<template>
  <div class="container-center-horizontal">
    <div class="download2 screen">
      <div class="overlap-group">
        <div class="vertical-stories">
          <div class="stories-2">
            <div class="overlap-group">
              <div class="background">
                <div class="background-1"></div>
              </div>
              <label for="file-input" v-if="isAvailable">
                <img class="rectangle-11" src="@/assets/rectangle-26@1x.png" />
                <div class="your-image-here custom-button">
                  <!-- <div class="text-16 smart-layers-pointers"></div> -->
                </div>
                <input
                  id="file-input"
                  type="file"
                  style="display: none;"
                  @change="onFile"
                  accept="image/*, application/pdf"
                />
              </label>
              <progress v-show="!isAvailable" class="pure-material-progress-circular center" />
              <div class="graphic-elements custom-radius"></div>
              <div class="text">
                <div class="text-17 raleway-black-torea-bay-40px">
                  <i18n path="global.title" />
                </div>
                <div class="group">
                  <div class="surname raleway-black-wild-sand-38px">
                    <i18n path="file.solis21" />
                  </div>
                  <div class="text-18 raleway-normal-white-34px">
                    <div v-html="$t('file.solis22')" />
                  </div>
                </div>
              </div>
              <div class="text-19 cormorantgaramond-normal-torea-bay-75px">
                <div>
                  <i18n path="global.line11" />
                </div>
                <div>
                  <i18n path="global.line12" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <canvas id="pdfCanvas" class="d-none"></canvas>
      <qrcode-capture class="d-none" />
    </div>
    <ErrorDialog v-model="d_error" :errorCode="errorCode" :errorText="errorText" />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import * as PDFJS from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;
import { QrcodeCapture } from "vue-qrcode-reader";

export default {
  name: "File",
  components: { QrcodeCapture, Footer },
  data() {
    return {
      d_error: false,
      errorText: null,
      errorCode: null,
      isAvailable: true,
    };
  },
  methods: {
    detect() {
      const canvas = document.getElementById("pdfCanvas");
      const barcodeDetector = new window.BarcodeDetector({
        formats: ["qr_code"],
      });
      barcodeDetector.detect(canvas).then((res) => {
        try {
          if (!res.length || !res[0].rawValue.startsWith("HC1:")) throw 1;
          this.isAvailable = false;
          this.$store
            .dispatch("LOAD_PASS_DATA", res[0].rawValue)
            .catch((error) => {
              this.isAvailable = true;
              this.d_error = true;
              this.errorText = this.$t("error.api");
              this.errorCode = error;
            });
        } catch (error) {
          this.isAvailable = true;
          console.log(error);
          this.d_error = true;
          this.errorText = this.$t("error.fileformat");
          this.errorCode = error;
        }
      });
    },
    onFile(event) {
      const file = event.target.files[0];
      if (!file) return;
      if (file.type == "application/pdf") this.servePdf(file);
      else this.serveImage(file);
    },
    servePdf(file) {
      const fileReader = new FileReader();
      const self = this;

      fileReader.onload = function () {
        const pdfdata = new Uint8Array(this.result);
        const loadingTask = PDFJS.getDocument(pdfdata);
        const PRINT_RESOLUTION = 150;
        const PRINT_UNITS = PRINT_RESOLUTION / 72.0;

        loadingTask.promise.then((pdf) => {
          pdf.getPage(1).then((page) => {
            var canvas = document.getElementById("pdfCanvas");
            var viewport = page.getViewport({
              scale: 1,
              dontFlip: true,
              rotation: 0,
            });
            canvas.width = viewport.width * PRINT_UNITS;
            canvas.height = viewport.height * PRINT_UNITS;
            var context = canvas.getContext("2d");
            const renderContext = {
              canvasContext: context,
              transform: [PRINT_UNITS, 0, 0, PRINT_UNITS, 0, 0],
              viewport: viewport,
            };
            const renderTask = page.render(renderContext);
            renderTask.promise.then(() => {
              self.detect();
            });
          });
        });
      };
      fileReader.readAsArrayBuffer(file);
    },
    serveImage(file) {
      const canvas = document.getElementById("pdfCanvas");
      canvas.width = 1920;
      canvas.height = 1080;
      var ctx = canvas.getContext("2d");
      var img = new Image();
      const self = this;
      img.onload = function () {
        ctx.drawImage(img, 0, 0);
        self.detect();
      };

      img.src = URL.createObjectURL(file);
    },
  },
};
</script>

<style scoped>
.dialog-hdr {
  width: 100%;
}
/* screen - download2 */

.download2 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  overflow: hidden;
  width: 1080px;
}

.download2 .overlap-group {
  height: 1920px;
  position: relative;
  width: 1080px;
}

.download2 .vertical-stories {
  display: flex;
  height: 1920px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.download2 .stories-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  min-width: 1080px;
}

.download2 .background {
  display: flex;
  height: 1920px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.download2 .background-1 {
  background-color: var(--link-water);
  flex: 1;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  width: 1082px;
}

.download2 .rectangle-11 {
  height: 144px;
  left: 17px;
  object-fit: cover;
  position: absolute;
  top: 66px;
  width: 144px;
}

.download2 .your-image-here {
  align-items: flex-end;
  /* background-image: url(~@/assets/group-11@1x.png); */
  background-image: url(~@/assets/file-search.png);
  background-color: var(--perano);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 815px;
  left: 121px;
  min-width: 657px;
  padding: 142.5px 161.3px;
  position: absolute;
  top: 916px;
}

.download2 .text-16 {
  -webkit-text-stroke: 2px #3f3913;
  color: #ffe54d;
  font-family: var(--font-family-fontawesome);
  font-size: 300px;
  letter-spacing: 0;
  line-height: 218.1px;
  min-height: 302px;
  min-width: 324px;
  pointer-events: auto;
  text-align: center;
  text-shadow: -10px 10px 6px #00000029;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.download2 .text-16:hover {
  transform: scale(1.1);
}

.download2 .graphic-elements {
  background-color: var(--torea-bay);
  height: 374px;
  left: 320px;
  position: absolute;
  top: 619px;
  width: 637px;
}

.download2 .text {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  left: 191px;
  min-height: 698px;
  position: absolute;
  top: 168px;
  width: 687px;
}

.download2 .text-17 {
  letter-spacing: 10.4px;
  line-height: 50px;
  margin-right: 1px;
  margin-top: -2px;
  min-height: 50px;
  min-width: 686px;
  text-align: center;
  white-space: nowrap;
}

.download2 .text-19 {
  position: absolute;
  width: 100%;
  letter-spacing: 0;
  line-height: 64px;
  margin-top: 297px;
  min-height: 157px;
  min-width: 618px;
  text-align: center;
  white-space: nowrap;
}

.download2 .group {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 11.23px;
  margin-top: 425px;
  min-height: 209px;
  width: 465px;
}

.download2 .surname {
  letter-spacing: 9.5px;
  line-height: 38px;
  margin-top: 4px;
  min-height: 47px;
  min-width: 220px;
  white-space: nowrap;
}

.download2 .text-18 {
  letter-spacing: 2.72px;
  line-height: 34px;
  margin-top: 38px;
  min-height: 122px;
  text-align: center;
  width: 465px;
}

.download2 .component-1-9 {
  align-items: flex-end;
  display: flex;
  height: 118px;
  left: 0;
  min-width: 1080px;
  position: absolute;
  top: 1802px;
}

.download2 .overlap-group1 {
  height: 120px;
  margin-bottom: -2px;
  position: relative;
  width: 1080px;
}

.download2 .rectangle-30 {
  background-color: var(--link-water);
  height: 118px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.download2 .text-20 {
  left: 184px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.download2 .text-21 {
  left: 609px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.download2 .copyright {
  left: 447px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 56px;
  white-space: nowrap;
}

.download2 .line-1 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1080px;
}

/*//////*/
.center {
  position: absolute;
  left: 40%;
  top: 67%;
}
.pure-material-progress-circular {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  padding: 0.25em;
  width: 15em;
  height: 15em;
  color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  background-color: transparent;
  font-size: 15px;
  overflow: hidden;
}

.pure-material-progress-circular::-webkit-progress-bar {
  background-color: transparent;
}

/* Indeterminate */
.pure-material-progress-circular:indeterminate {
  -webkit-mask-image: linear-gradient(transparent 50%, black 50%),
    linear-gradient(to right, transparent 50%, black 50%);
  mask-image: linear-gradient(transparent 50%, black 50%),
    linear-gradient(to right, transparent 50%, black 50%);
  animation: pure-material-progress-circular 6s infinite
    cubic-bezier(0.3, 0.6, 1, 1);
}

:-ms-lang(x),
.pure-material-progress-circular:indeterminate {
  animation: none;
}

.pure-material-progress-circular:indeterminate::before,
.pure-material-progress-circular:indeterminate::-webkit-progress-value {
  content: "";
  display: block;
  box-sizing: border-box;
  margin-bottom: 0.25em;
  border: solid 0.5em transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  width: 100% !important;
  height: 100%;
  background-color: transparent;
  animation: pure-material-progress-circular-pseudo 0.75s infinite linear
    alternate;
}

.pure-material-progress-circular:indeterminate::-moz-progress-bar {
  box-sizing: border-box;
  border: solid 0.5em transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  animation: pure-material-progress-circular-pseudo 0.75s infinite linear
    alternate;
}

.pure-material-progress-circular:indeterminate::-ms-fill {
  animation-name: -ms-ring;
}

@keyframes pure-material-progress-circular {
  0% {
    transform: rotate(0deg);
  }
  12.5% {
    transform: rotate(180deg);
    animation-timing-function: linear;
  }
  25% {
    transform: rotate(630deg);
  }
  37.5% {
    transform: rotate(810deg);
    animation-timing-function: linear;
  }
  50% {
    transform: rotate(1260deg);
  }
  62.5% {
    transform: rotate(1440deg);
    animation-timing-function: linear;
  }
  75% {
    transform: rotate(1890deg);
  }
  87.5% {
    transform: rotate(2070deg);
    animation-timing-function: linear;
  }
  100% {
    transform: rotate(2520deg);
  }
}

@keyframes pure-material-progress-circular-pseudo {
  0% {
    transform: rotate(-30deg);
  }
  29.4% {
    border-left-color: transparent;
  }
  29.41% {
    border-left-color: currentColor;
  }
  64.7% {
    border-bottom-color: transparent;
  }
  64.71% {
    border-bottom-color: currentColor;
  }
  100% {
    border-left-color: currentColor;
    border-bottom-color: currentColor;
    transform: rotate(225deg);
  }
}
</style>